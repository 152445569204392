import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { getLanguages } from 'react-localize-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Paper, Box, Grid } from '@mui/material';
import LoginPanel from './LoginPanel';
import { fetchTranslationsAction } from '../../actions/translationsActions';
import { MessageToastLogin } from '../Generic';
import { handleSelectClientAction } from './actions/loginActions';
import { isMobileSize } from '../../helpers/functions/functions';

import PlaceholderIMG from '../../assets/images/placeholder.svg';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recovery: false,
			recovery_token : null,
      recovery_medium : null,
      recovery_value : null
		};
	}

	componentDidMount() {
		if (this.props.match.path == '/backoffice' && this.props.platform == 'backoffice') {
			this.props.handleSelectClientAction({ id: 0 }, 'backoffice');
		} else if (this.props.platform == 'frontoffice') {
			if (!this.props.login) {
				if (this.props.platform == 'frontoffice' && this.props.settings.clients.length > 1) {
					if (this.props.match.params.client) {
						var selectedClient = this.props.settings.clients.find((client) => {
							return client.short_name === this.props.match.params.client;
						});

						if (selectedClient) {
							this.props.handleSelectClientAction(selectedClient, 'frontoffice');
								if (this.props.match.path.split('/').some(item => item =='password')){	
                  let recoveryMediums = this.props.settings.auth.supported_recovery_password_mediums
                    ? this.props.settings.auth.supported_recovery_password_mediums
                      .replace(/,\s*$/, '')
                      .split(',')
                      .map((item) => item.trim())
                      .filter((item) => item.length > 0)
                  : [];

                  let qparams_left = '';
                  if (typeof this.props.query?.recovery_token !== 'undefined' && typeof this.props.query?.recovery_medium !== 'undefined' && typeof this.props.query?.recovery_value !== 'undefined' && recoveryMediums.includes(this.props.query?.recovery_medium)) {
                    this.setState({
                      recovery : true,
                      recovery_token: this.props.query.recovery_token, 
                      recovery_medium: this.props.query.recovery_medium,
                      recovery_value: this.props.query.recovery_value,  
                    });
                    
                    let qparams = Object.entries(this.props.query);
                    if (qparams) {
                      qparams.map((item) => {          
                        if (!['recovery_token', 'recovery_medium', 'recovery_value'].includes(item[0])) {
                          if (qparams_left == '') {
                            qparams_left = '?' + item[0] + '=' + item[1];
                          } else {
                            qparams_left = '&' + item[0] + '=' + item[1];
                          }
                        }
                      });
                    }
                  } else {
                    this.setState({
                      recovery : true,
                      recovery_token: null, 
                      recovery_medium: null,
                      recovery_value: null,  
                    });
                  }
                  this.props.replace(this.props.location.pathname + qparams_left);
								} else {
									this.setState({
										recovery : false,
										recovery_token: null,
                    recovery_medium : null,
                    recovery_value : null  
									});
								}
						} else {
							this.props.replace('/login' + this.props.location.search);
						}
					} else if (typeof this.props.client === 'undefined' || this.props.client === null || this.props.client.id === 0) {
					  this.props.handleSelectClientAction(null, 'frontoffice')
					}
				} else if (this.props.settings.clients.length == 1) {
          this.props.handleSelectClientAction(this.props.settings.clients[0], 'frontoffice')
        }
			}
		}
	}

	render() {
		return this.props.platform == 'frontoffice' ? (
			<Grid container component="main" className="login-main">
				<MessageToastLogin />
				<Grid item xs={12} lg={7} className="login-content-left-image">
					<Box height="100%" className="login-content-left-title" p={1} href="/">
						<Link component={RouterLink} to={'/'} color="inherit" underline="none">
							<img
								src={
									typeof this.props.settings.site !== 'undefined' &&
									this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image']
										? this.props.config.apihost +
										  '/configuration/files/' +
										  this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image'].uuid
										: PlaceholderIMG
								}
								alt="Login logo"
								style={{ width: '70%' }}
							/>
						</Link>
					</Box>
				</Grid>
				<Grid item xs={12} lg={5} component={Paper} elevation={6} square>
					<LoginPanel recovery={this.state.recovery} recovery_token={this.state.recovery_token} recovery_medium={this.state.recovery_medium} recovery_value={this.state.recovery_value} />
				</Grid>
			</Grid>
		) : (
			<Grid container component="main" justifyContent="center" className="login-main login-content-left-image">
				<MessageToastLogin />
				<Grid item xs={12} lg={12}>
					<Box height="100%" className="login-content-left-title" p={1} href="/">
						<Link component={RouterLink} to={'/'} color="inherit" underline="none">
							<img
								src={
									typeof this.props.settings.site !== 'undefined' &&
									this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image']
										? this.props.config.apihost +
										  '/configuration/files/' +
										  this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image'].uuid
										: PlaceholderIMG
								}
								alt="Login logo"
								className="generic-width-backoffice"
							/>
						</Link>
					</Box>
				</Grid>
				<Grid item xs={6} lg={6}>
					<LoginPanel />
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	languages: getLanguages(state.localize),
	settings: state.settings,
	config: state.config,
	client: state.login.client,
	login: state.login.login,
  query : state.router.location?.query,
});

export default connect(mapStateToProps, { fetchTranslationsAction, handleSelectClientAction, replace })(Login);
