/**Country formats with hours */
const SWEH = 'yyyy-MM-DD HH:mm';
const GBRH = 'dd/MM/yyyy HH:mm';
const USAH = 'MM/DD/yyyy HH:mm';
const PRTH = 'dd-MM-yyyy HH:mm';
const SWAH = 'dd-MM-yyyy HH:mm';

/**Country formats without hours */
const SWE = 'yyyy-MM-DD';
const GBR = 'dd/MM/yyyy';
const USA = 'MM/DD/yyyy';
const PRT = 'dd-MM-yyyy';
const SWA = 'dd-MM-yyyy';

/**Country formats with hours for datetimepickers*/
const SWEH_DATETIME_PICKERS = 'yyyy-MM-dd HH:mm';
const GBRH_DATETIME_PICKERS = 'dd/MM/yyyy HH:mm';
const USAH_DATETIME_PICKERS = 'M/d/yyyy HH:mm';
const PRTH_DATETIME_PICKERS = 'dd-MM-yyyy HH:mm';
const SWAH_DATETIME_PICKERS = 'dd-MM-yyyy HH:mm';

/**Country formats without hours for datepickers*/
const SWE_DATE_PICKERS = 'yyyy-MM-dd';
const GBR_DATE_PICKERS = 'dd/MM/yyyy';
const USA_DATE_PICKERS = 'M/d/yyyy';
const PRT_DATE_PICKERS = 'dd-MM-yyyy';
const SWA_DATE_PICKERS = 'dd-MM-yyyy';

export default {
	TYPES: {
		DATE: '',
		DATETIME: 'H',
		DATE_PICKER: '_DATE_PICKERS',
		DATETIME_PICKERS: 'H_DATETIME_PICKERS',
	},
	FORMATS: {
		SWE,
		GBR,
		USA,
		PRT,
		SWA,
		SWEH,
		GBRH,
		USAH,
		PRTH,
		SWAH,
		SWE_DATE_PICKERS,
		GBR_DATE_PICKERS,
		USA_DATE_PICKERS,
		PRT_DATE_PICKERS,
		SWA_DATE_PICKERS,
		SWEH_DATETIME_PICKERS,
		GBRH_DATETIME_PICKERS,
		USAH_DATETIME_PICKERS,
		PRTH_DATETIME_PICKERS,
		SWAH_DATETIME_PICKERS,
	},
};
